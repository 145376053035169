import React, { useState } from "react";
import style from "../assets/css/usercount.module.scss";
import user from "../assets/images/user.png";
import sertifikat from "../assets/images/sertifikat.png";
import compony from "../assets/images/compony.png";
import isci from "../assets/images/isci.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../constants";

const UserCount = ({ bgColor, txtColor }) => {
  const { t, i18n } = useTranslation();

  const [userCount, setUserCount] = useState([]);
  const [certificateCount, setCertificateCount] = useState([]);
  const [componyCount, setComponyCount] = useState([]);
  const [workerCount, setWorkerCount] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const userCountData = await fetchSettingsData("userCount");
      const certificateCountData = await fetchSettingsData("certificateCount");
      const componyCountData = await fetchSettingsData("componyCount");
      const workerCountData = await fetchSettingsData("workerCount");
      setUserCount(userCountData);
      setCertificateCount(certificateCountData);
      setComponyCount(componyCountData);
      setWorkerCount(workerCountData);
    };
    getSettingsData();
  }, []);

  return (
    style && (
      <div className={style.usercount}>
        <div className={`container ${style.usercount__container}`}>
          <div className={`row ${style.usercount__container__row}`}>
            <div
              className={`col-lg-3 col-md-6 col-12 ${style.usercount__container__row__col}`}
            >
              <div
                style={{ backgroundColor: bgColor }}
                className={style.usercount__container__row__col__image}
              >
                <img
                  className={style.usercount__container__row__col__image__img}
                  src={user}
                  alt=""
                />
              </div>
              <h5
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h5}
              >
                {t("istifadəçi sayı")}
              </h5>
              <h2
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h2}
              >
                {userCount &&
                  userCount.map((s) => {
                    return s.value;
                  })}
              </h2>
            </div>
            <div
              className={`col-lg-3 col-md-6 col-12 ${style.usercount__container__row__col}`}
            >
              <div
                style={{ backgroundColor: bgColor }}
                className={style.usercount__container__row__col__image}
              >
                <img
                  className={style.usercount__container__row__col__image__img}
                  src={sertifikat}
                  alt=""
                />
              </div>
              <h5
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h5}
              >
                {t("sertifikat sayı")}
              </h5>
              <h2
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h2}
              >
                {certificateCount &&
                  certificateCount.map((s) => {
                    return s.value;
                  })}
              </h2>
            </div>
            <div
              className={`col-lg-3 col-md-6 col-12 ${style.usercount__container__row__col}`}
            >
              <div
                style={{ backgroundColor: bgColor }}
                className={style.usercount__container__row__col__image}
              >
                <img
                  className={style.usercount__container__row__col__image__img}
                  src={compony}
                  alt=""
                />
              </div>
              <h5
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h5}
              >
                {t("şirkət sayı")}
              </h5>
              <h2
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h2}
              >
                {componyCount &&
                  componyCount.map((s) => {
                    return s.value;
                  })}
              </h2>
            </div>
            <div
              className={`col-lg-3 col-md-6 col-12 ${style.usercount__container__row__col}`}
            >
              <div
                style={{ backgroundColor: bgColor }}
                className={style.usercount__container__row__col__image}
              >
                <img
                  className={style.usercount__container__row__col__image__img}
                  src={isci}
                  alt=""
                />
              </div>
              <h5
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h5}
              >
                {t("işçi sayı")}
              </h5>
              <h2
                style={{ color: txtColor }}
                className={style.usercount__container__row__col__h2}
              >
                {workerCount &&
                  workerCount.map((s) => {
                    return s.value;
                  })}
              </h2>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default UserCount;
