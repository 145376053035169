import React from "react";
import { Link } from "react-router-dom";
import style from "../assets/css/newscard.module.scss";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IMAGE_URL } from "../constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

const NewsCard = ({ blog }) => {
  const { t, i18n } = useTranslation();
  return (
    style && (
      <div className={style.newscard}>
        <div className={style.newscard__card}>
          <div className={style.newscard__card__image}>
            <img
              className={style.newscard__card__image__img}
              src={`${IMAGE_URL}${blog.image.filePath}`}
              alt={blog.image.fileName}
            />
          </div>
          <div className={style.newscard__card__body}>
            <h3 className={style.newscard__card__body__title}>{blog.title}</h3>
            {/* <p className={style.newscard__card__body__desc}>
              {ReactHtmlParser(
                blog.body.length > 150
                  ? `${blog.body.slice(0, 150)}....`
                  : blog.body
              )}
            </p> */}
            <Link
              to={`/blogs/${blog.id}`}
              className={style.newscard__card__body__link}
            >
              {t("daha ətraflı")} <MdKeyboardArrowRight />
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default NewsCard;
