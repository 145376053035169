import React from "react";
import style from "../assets/css/index.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsMap } from "react-icons/bs";
import { BiBriefcaseAlt } from "react-icons/bi";
import { BiUserCheck } from "react-icons/bi";
import { FiCpu } from "react-icons/fi";
import { RiTruckLine } from "react-icons/ri";
import TrainingsSubCategory from "../components/TrainingsSubCategory";
import NdtServices from "../components/NdtServices";
import UserCount from "../components/UserCount";
import Feedback from "../components/Feedback";
import News from "../components/News";
import Partners from "../components/Partners";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL, IMAGE_URL } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlides, slideFetch, getStatus } from "../features/slideSlice";
import LoadingBox from "../components/LoadingBox";
import { Helmet } from "react-helmet";
import TrainingsSubCategoryCard from "../components/TrainingsSubCategoryCard";

const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const searchRef = useRef();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const slides = useSelector(getAllSlides);
  const status = useSelector(getStatus);
  useEffect(() => {
    const getCategory = async () => {
      setLoading(true);
      await axios
        .get(`${BASE_URL}/categories`)
        .then((res) => {
          setCategories(res?.data);
          setLoading(false);
        })
        .catch((err) => {});
    };
    getCategory();
  }, [dispatch]);
  useEffect(() => {
    dispatch(slideFetch());
  }, [dispatch]);

  const [ixCtg, setIxCtg] = useState([]);
  const [muhCtg, setMuhCtg] = useState([]);
  useEffect(() => {
    const getIxCtg = async () => {
      await axios
        .get(
          `${BASE_URL}/educationsubcategories?educationCategoryId=${Number(
            t("ixCtgId")
          )}&pageSize=50`
        )
        .then((res) => {
          setIxCtg(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getIxCtg();
    const getMuhCtg = async () => {
      await axios
        .get(
          `${BASE_URL}/educationsubcategories?educationCategoryId=${Number(
            t("muhCtgId")
          )}&pageSize=50`
        )
        .then((res) => {
          setMuhCtg(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getMuhCtg();
  }, []);

  if (status === "loading") return <LoadingBox />;

  if (status === "loading" || loading === true) return <LoadingBox />;
  var settings = {
    infinite: true,
    speed: 2000,
    cssEase: "ease-in-out",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
  };

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Home</title>
        </Helmet>
        <div className={style.home}>
          <Slider {...settings} className={style.home__cover}>
            {slides &&
              slides.map((slide, index) => (
                <div key={index}>
                  <img
                    src={`${IMAGE_URL}${slide.image.filePath}`}
                    alt="slide"
                  />
                </div>
              ))}
          </Slider>
          <div className={style.home__slidecontent}>
            <div className={style.home__slidecontent__wrapper}>
              <div className={style.home__slidecontent__wrapper__title}>
                <h1
                  className={style.home__slidecontent__wrapper__title__heading}
                >
                  {t("peşəkar işçilər naminə")}
                </h1>
                <p className={style.home__slidecontent__wrapper__title__desc}>
                  {t(
                    "ideal layihələr həyata keçirmək üçün, mütəxəsislər yetişdiririk."
                  )}
                </p>
              </div>
              <div className={style.home__slidecontent__wrapper__search}>
                <input
                  className={style.home__slidecontent__wrapper__search__input}
                  type="text"
                  placeholder={t("təlimləri axtarın") + "..."}
                  ref={searchRef}
                />
                <button
                  onClick={() => {
                    navigate(`/trainings?search=${searchRef.current.value}`);
                  }}
                  className={style.home__slidecontent__wrapper__search__button}
                >
                  {t("axtar")}
                </button>
              </div>
              <div className={style.home__slidecontent__wrapper__service}>
                <Link
                  to={`trainingscategory/${categories && categories[1]?.id}`}
                  className={style.home__slidecontent__wrapper__service__link}
                >
                  <BsMap />
                  <small>{categories && categories[1]?.title}</small>
                </Link>
                <Link
                  to={`trainingscategory/${categories && categories[0]?.id}`}
                  className={style.home__slidecontent__wrapper__service__link}
                >
                  <BiBriefcaseAlt />
                  <small>{categories && categories[0]?.title}</small>
                </Link>
                <Link
                  href=""
                  className={style.home__slidecontent__wrapper__service__link}
                >
                  <BiUserCheck />
                  <small>{t("konsaltinq")}</small>
                </Link>
                <Link
                  to="/engineerservicescategory"
                  className={style.home__slidecontent__wrapper__service__link}
                >
                  <FiCpu />
                  <small>{t("mühəndislik ximətləri")}</small>
                </Link>
                <Link
                  to="/equipments"
                  className={style.home__slidecontent__wrapper__service__link}
                >
                  <RiTruckLine />
                  <small>{t("təchizat")}</small>
                </Link>
              </div>
            </div>
          </div>
          <section className={style.home__specialization_section}>
            <div
              className={`container ${style.home__specialization_section__container}`}
            >
              <h1
                className={style.home__specialization_section__container__title}
              >
                {t("ixtisas artırma təlimləri")}
              </h1>
              <div className="row">
                {ixCtg &&
                  ixCtg.map((subCategory, index) => (
                    <TrainingsSubCategoryCard
                      key={index}
                      subCategory={subCategory}
                    />
                  ))}
              </div>
            </div>
          </section>
          <section className={style.home__engineer_section}>
            <div
              className={`container ${style.home__egineer_section__container}`}
            >
              <h1 className={style.home__engineer_section__container__title}>
                {t("mühəndislik təlimləri")}
              </h1>
              <div className="row">
                {muhCtg &&
                  muhCtg.map((subCategory, index) => (
                    <TrainingsSubCategoryCard
                      key={index}
                      subCategory={subCategory}
                    />
                  ))}
              </div>
            </div>
          </section>
          <section className={style.home__ndtservices}>
            <div className={`container ${style.home__ndtservices__container}`}>
              <h1 className={style.home__ndtservices__container__title}>
                {t("mühəndislik ximətləri")}
              </h1>
              <NdtServices />
            </div>
          </section>
          <section className={style.home__usercount}>
            <UserCount bgColor="#e87813" txtColor="##18113c" />
          </section>
          <section className={style.home__feedback}>
            <div className={`container ${style.home__feedback__container}`}>
              <h1 className={style.home__feedback__container__title}>
                {t("müştərilər haqqımızda nə deyir")}
              </h1>
              <p className={style.home__feedback__container__desc}>
                {t("müştəri rəyləri")}
              </p>
              <Feedback />
            </div>
          </section>
          <section className={style.home__news}>
            <div className={`container ${style.home__news__container}`}>
              <h1 className={style.home__news__container__title}>
                {t("xəbərlər")}
              </h1>
              <p className={style.home__news__container__desc}>
                {t("ən son xəbərlər")}
              </p>
              <News />
            </div>
          </section>
          <section className={style.home__partners}>
            <div className={`container ${style.home__partners__container}`}>
              <h1 className={style.home__partners__container__title}>
                {t("tərəfdaşlar")}
              </h1>
              <p className={style.home__partners__container__desc}>
                {t("birlikdə daha güclüyük")}
              </p>
              <Partners />
            </div>
          </section>
        </div>
      </>
    )
  );
};

export default Home;
