import React from "react";
import style from "../assets/css/trainingssubcategorycard.module.scss";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../constants";

const TrainingsSubCategoryCard = ({ subCategory }) => {
  return (
    style && (
      <div className={`col-lg-4 col-md-4 col-sm-12 col-12 ${style.card}`}>
        <Link
          to={`/trainings?sub=${subCategory.id}`}
          className={style.card__link}
        >
          <div className={`card ${style.card__link__item}`}>
            <div className={style.card__link__item__image}>
              <img
                className={style.card__link__item__image__img}
                src={`${IMAGE_URL}${subCategory?.image?.filePath}`}
                alt={subCategory?.image?.fileName}
              />
            </div>
            <div className={style.card__link__item__title}>
              <p className={style.card__link__item__title__txt}>
                {subCategory?.title}
              </p>
            </div>
          </div>
        </Link>
      </div>
    )
  );
};

export default TrainingsSubCategoryCard;
