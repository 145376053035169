import React from "react";
import style from "../assets/css/message.module.scss";
import { BsChatLeftText } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../constants";

const Message = () => {
  const [dropdown, setDropdown] = useState(false);
  const { t, i18n } = useTranslation();

  const [whatsappNumber, setWhatsappNumber] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const whatsappNumberData = await fetchSettingsData("whatsappNumber");
      setWhatsappNumber(whatsappNumberData);
    };
    getSettingsData();
  }, []);

  window.addEventListener("click", () => {
    setDropdown(false);
  });

  return (
    style && (
      <div className={style.message}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setDropdown(true);
          }}
          className={`${!dropdown ? "d-none" : ""} ${style.message__dropdown}`}
        >
          <div className={style.message__dropdown__header}>
            <div
              className={style.message__dropdown__header__close}
              onClick={(e) => {
                e.stopPropagation();
                setDropdown(false);
              }}
            >
              x
            </div>
            <div className={style.message__dropdown__header__title}>
              {t("kömək lazımdır? bizimlə əlaqə qurun")}
            </div>
            <div className={style.message__dropdown__header__desc}>
              {t("aşağıdakı social platformalardan birinə klikləyin")}
            </div>
          </div>
          <div className={style.message__dropdown__body}>
            <div className={style.message__dropdown__body__wrapper}>
              <a
                href={`https://wa.me/${
                  whatsappNumber &&
                  whatsappNumber.map((s) => {
                    return s.value;
                  })
                }?text=Salam`}
                className={style.message__dropdown__body__wrapper__link}
              >
                <div
                  className={style.message__dropdown__body__wrapper__link__item}
                >
                  <div
                    className={
                      style.message__dropdown__body__wrapper__link__item__whatsapp_icon
                    }
                  >
                    <FaWhatsapp
                      className={
                        style.message__dropdown__body__wrapper__link__item__whatsapp_icon__ico
                      }
                    />
                  </div>
                  <div
                    className={
                      style.message__dropdown__body__wrapper__link__item__content
                    }
                  >
                    <h5
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__title
                      }
                    >
                      Whatsapp
                    </h5>
                    <p
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__desc
                      }
                    >
                      {t("müştəri dəstəyi")}
                    </p>
                    <div
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__online
                      }
                    >
                      {t("onlayn")}
                    </div>
                  </div>
                </div>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/archicoengineeringcompany/Salam"
                className={style.message__dropdown__body__wrapper__link}
              >
                <div
                  className={style.message__dropdown__body__wrapper__link__item}
                >
                  <div
                    className={
                      style.message__dropdown__body__wrapper__link__item__linkedin_icon
                    }
                  >
                    <FaLinkedinIn
                      className={
                        style.message__dropdown__body__wrapper__link__item__linkedin_icon__ico
                      }
                    />
                  </div>
                  <div
                    className={
                      style.message__dropdown__body__wrapper__link__item__content
                    }
                  >
                    <h5
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__title
                      }
                    >
                      Linkedin
                    </h5>
                    <p
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__desc
                      }
                    >
                      {t("müştəri dəstəyi")}
                    </p>
                    <div
                      className={
                        style.message__dropdown__body__wrapper__link__item__content__online
                      }
                    >
                      {t("onlayn")}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          className={style.message__icon}
          onClick={(e) => {
            e.stopPropagation();
            setDropdown(!dropdown);
          }}
        >
          <div className={style.message__icon__avatar}>
            <BsChatLeftText className={style.message__icon__avatar__image} />
          </div>
          <div
            className={` ${dropdown ? "d-none" : ""} ${
              style.message__icon__pulse
            }`}
          ></div>
          <div
            className={` ${dropdown ? "d-none" : ""} ${
              style.message__icon__pulse
            }`}
          ></div>
          <div
            className={` ${dropdown ? "d-none" : ""} ${
              style.message__icon__notify
            }`}
          >
            <span className={style.message__icon__notify__count}>1</span>
          </div>
        </div>
      </div>
    )
  );
};

export default Message;
