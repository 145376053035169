import React from "react";
import { Link } from "react-router-dom";
import style from "../assets/css/contactus.module.scss";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t, i18n } = useTranslation();

  return (
    style && (
      <div className={style.contactus}>
        <div className={`container ${style.contactus__container}`}>
          <div className={`row ${style.contactus__container__row}`}>
            <div className={`col-lg-6 ${style.contactus__container__row__col}`}>
              <h1 className={style.contactus__container__row__col__title}>
                {t("bizimlə əlaqə")}
              </h1>
              <p className={style.contactus__container__row__col__desc}>
                {t("əlavə məlumat almaq istəyirsiniz və ya sualınız var")}
              </p>
            </div>
            <div
              className={`col-lg-6 ${style.contactus__container__row__col_link}`}
            >
              <Link
                className={style.contactus__container__row__col_link__link}
                to="/contact"
              >
                {t("bizimlə əlaqə")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ContactUs;
