import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import style from "../assets/css/trainings.module.scss";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Shape from "../components/Shape";
import FeaturedTrainings from "../components/FeaturedTrainings";
import { IMAGE_URL } from "../constants";
import LoadingBox from "../components/LoadingBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEducationEvents,
  getAllEducationEvents,
  getError,
  getPagination,
  getStatus,
} from "../features/educationEventsSlice";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const EducationEvents = () => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const educationEvents = useSelector(getAllEducationEvents);
  const pagination = useSelector(getPagination);
  const status = useSelector(getStatus);
  const error = useSelector(getError);

  const memoizedFetchBlog = useMemo(() => {
    return () => {
      dispatch(
        fetchEducationEvents({
          pageNumber,
        })
      );
    };
  }, [dispatch, pageNumber]);

  useEffect(() => {
    memoizedFetchBlog();
  }, [memoizedFetchBlog]);

  if (status === "loading") {
    return <LoadingBox />;
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Education Events</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("təlim cədvəli")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>
              {t("təlim cədvəli")}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />

        <section className={style.trainings}>
          <div className={`container ${style.trainings__container}`}>
            <div className={`row ${style.trainings__container__row}`}>
              <div
                className={`col-lg-9 ${style.trainings__container__row__col9}`}
              >
                <div
                  className={`row ${style.trainings__container__row__col9__row}`}
                >
                  {status === "succeeded" ? (
                    educationEvents &&
                    educationEvents.map((educationEvent, index) => (
                      <div
                        key={index}
                        className={`${list ? "col-lg-12" : "col-lg-6"} ${
                          style.trainings__container__row__col9__row__col12
                        }`}
                      >
                        <div
                          className={`${!list ? "d-flex flex-column" : ""} ${
                            style.trainings__container__row__col9__row__col12__card
                          }`}
                        >
                          <div
                            style={{ width: !list ? "100%" : "" }}
                            className={
                              style.trainings__container__row__col9__row__col12__card__image
                            }
                          >
                            <Link
                              to={`/trainings/${educationEvent?.education?.id}`}
                            >
                              <img
                                style={{
                                  width: !list ? "100%" : "",
                                  borderBottomLeftRadius: list ? "7px" : "0",
                                  borderTopRightRadius: !list ? "7px" : "0",
                                }}
                                className={
                                  style.trainings__container__row__col9__row__col12__card__image__img
                                }
                                src={`${IMAGE_URL}${educationEvent?.education?.image?.filePath}`}
                                alt={educationEvent?.education?.image?.fileName}
                              />
                            </Link>
                          </div>
                          <div
                            className={
                              style.trainings__container__row__col9__row__col12__card__body
                            }
                          >
                            <h3
                              className={
                                style.trainings__container__row__col9__row__col12__card__body__h3
                              }
                              onClick={() => {
                                navigate(
                                  `/trainings/${educationEvent?.education?.id}`
                                );
                              }}
                            >
                              {educationEvent?.education?.title}
                            </h3>
                            <p
                              className={
                                style.trainings__container__row__col9__row__col12__card__body__p
                              }
                            >
                              {ReactHtmlParser(
                                educationEvent?.education?.information.length >
                                  250
                                  ? educationEvent?.education?.information.slice(
                                      0,
                                      250
                                    )
                                  : educationEvent?.education?.information
                              )}
                            </p>
                            <span
                              className={
                                style.trainings__container__row__col9__row__col12__card__body__span
                              }
                            >
                              <span style={{ color: "#e87813" }}>
                                Başlama vaxtı:
                              </span>{" "}
                              {educationEvent?.startDate?.slice(0, 10)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
                {educationEvents.length > 0 ? (
                  <div
                    className={
                      style.trainings__container__row__col9__pagination
                    }
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pagination.TotalPages}
                        page={pagination.CurrentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#e87813 !important",
                            color: "#fffcfc !important",
                            margin: "0",
                          },
                          "& .MuiPaginationItem-root:hover": {
                            color: "#e87813 !important",
                          },
                          "& .Mui-selected:hover": {
                            backgroundColor: "#e87813 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div
                className={`col-lg-3 ${style.trainings__container__row__col3}`}
              >
                <FeaturedTrainings />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
