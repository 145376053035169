import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import style from "../assets/css/trainings.module.scss";
import { FaList } from "react-icons/fa";
import { CgMenuGridR } from "react-icons/cg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Shape from "../components/Shape";
import FeaturedTrainings from "../components/FeaturedTrainings";
import LoadingBox from "../components/LoadingBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEngineerServices,
  getAllEngineerService,
  getEngineerServicePagination,
  getEngineerServiceStatus,
} from "../features/engineerServicesSlice";
import { IMAGE_URL } from "../constants";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export const EngineerServices = () => {
  const { t, i18n } = useTranslation();
  const [list, setList] = useState(true);
  const [filter, setFilter] = useState(true);
  const query = useQuery();
  const sub = Number(query.get("sub"));
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const engineerServices = useSelector(getAllEngineerService);
  const pagination = useSelector(getEngineerServicePagination);
  const status = useSelector(getEngineerServiceStatus);
  const memoizedFetchBlog = useMemo(() => {
    return () => {
      dispatch(
        fetchEngineerServices({
          search: "",
          pageNumber,
          sub,
          filter,
        })
      );
    };
  }, [dispatch, pageNumber, sub, filter]);

  useEffect(() => {
    memoizedFetchBlog();
  }, [memoizedFetchBlog]);

  if (status === "loading") {
    return <LoadingBox />;
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Engineer Services</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>
            {t("mühəndislik ximətləri")}
          </h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>
              {t("mühəndislik ximətləri")}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />

        <section className={style.trainings}>
          <div className={`container ${style.trainings__container}`}>
            <div className={`row ${style.trainings__container__row}`}>
              <div
                className={`col-lg-9 ${style.trainings__container__row__col9}`}
              >
                <div className={style.trainings__container__row__col9__filter}>
                  <div
                    className={
                      style.trainings__container__row__col9__filter__select
                    }
                  >
                    <span
                      className={
                        style.trainings__container__row__col9__filter__select__span
                      }
                    >
                      {t("sırala")}
                    </span>
                    <select
                      className={
                        style.trainings__container__row__col9__filter__select__sel
                      }
                      name=""
                      id=""
                      defaultValue={
                        filter ? `${t("ən son")}` : `${t("ən köhnə")}`
                      }
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                    >
                      <option value="true">{t("ən son")}</option>
                      <option value="false">{t("ən köhnə")}</option>
                    </select>
                  </div>
                  <div
                    className={
                      style.trainings__container__row__col9__filter__menu
                    }
                  >
                    <ul
                      className={
                        style.trainings__container__row__col9__filter__menu__list
                      }
                    >
                      <li
                        onClick={() => {
                          setList(true);
                        }}
                        style={{
                          color: list ? "#e87813" : "#5c5776",
                        }}
                        className={
                          style.trainings__container__row__col9__filter__menu__list__item
                        }
                      >
                        <FaList />
                      </li>
                      <li
                        onClick={() => {
                          setList(false);
                        }}
                        style={{
                          color: !list ? "#e87813" : "#5c5776",
                        }}
                        className={
                          style.trainings__container__row__col9__filter__menu__list__item
                        }
                      >
                        <CgMenuGridR />
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`row ${style.trainings__container__row__col9__row}`}
                >
                  {status === "succeeded" ? (
                    engineerServices.map((engineerService, index) => (
                      <div
                        key={index}
                        className={`${list ? "col-lg-12" : "col-lg-6"} ${
                          style.trainings__container__row__col9__row__col12
                        }`}
                        style={{ marginTop: "24px" }}
                      >
                        <div
                          className={`${!list ? "d-flex flex-column" : ""} ${
                            style.trainings__container__row__col9__row__col12__card
                          }`}
                        >
                          <div
                            style={{ width: !list ? "100%" : "" }}
                            className={
                              style.trainings__container__row__col9__row__col12__card__image
                            }
                          >
                            <Link
                              to={`/engineerservices/${engineerService.id}`}
                            >
                              <img
                                style={{
                                  width: !list ? "100%" : "",
                                  borderBottomLeftRadius: list ? "7px" : "0",
                                  borderTopRightRadius: !list ? "7px" : "0",
                                }}
                                className={
                                  style.trainings__container__row__col9__row__col12__card__image__img
                                }
                                src={`${IMAGE_URL}${engineerService.image.filePath}`}
                                alt={engineerService.image.fileName}
                              />
                            </Link>
                          </div>
                          <div
                            className={
                              style.trainings__container__row__col9__row__col12__card__body
                            }
                          >
                            <h3
                              className={
                                style.trainings__container__row__col9__row__col12__card__body__h3
                              }
                              onClick={() => {
                                navigate(
                                  `/engineerservices/${engineerService.id}`
                                );
                              }}
                            >
                              {engineerService.title}
                            </h3>
                            <p
                              className={
                                style.trainings__container__row__col9__row__col12__card__body__p
                              }
                            >
                              {engineerService.subTitle.length > 150
                                ? engineerService.subTitle.slice(0, 150)
                                : engineerService.subTitle}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
                {engineerServices.length > 0 ? (
                  <div
                    className={
                      style.trainings__container__row__col9__pagination
                    }
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pagination.TotalPages}
                        page={pagination.CurrentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#e87813 !important",
                            color: "#fffcfc !important",
                            margin: "0",
                          },
                          "& .MuiPaginationItem-root:hover": {
                            color: "#e87813 !important",
                          },
                          "& .Mui-selected:hover": {
                            backgroundColor: "#e87813 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div
                className={`col-lg-3 ${style.trainings__container__row__col3}`}
              >
                <FeaturedTrainings />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
