import React from "react";
import style from "./headerdown.module.scss";
import { Toaster } from "react-hot-toast";

const HeaderDown = ({ children }) => {
  return (
    <>
      <div>
        <Toaster />
      </div>
      <div className={style.headerdown}>
        <div className={style.headerdown__title}>{children}</div>
      </div>
    </>
  );
};

export default HeaderDown;
