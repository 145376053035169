import React from "react";
import { Link, useParams } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import style from "../assets/css/equipmentsdetail.module.scss";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleEquipment,
  getSingleEquipments,
  getSingleError,
  getSingleStatus,
} from "../features/equipmentsSlice";
import { useEffect } from "react";
import LoadingBox from "../components/LoadingBox";
import ReactHtmlParser from "react-html-parser";
import { IMAGE_URL } from "../constants";
import NotFound from "./NotFound";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const EquipmentsDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const equipment = useSelector(getSingleEquipments);
  const status = useSelector(getSingleStatus);
  const error = useSelector(getSingleError);
  useEffect(() => {
    dispatch(fetchSingleEquipment({ id }));
  }, [dispatch, id]);
  if (status === "loading") return <LoadingBox />;
  if (error.code === "ERR_BAD_REQUEST") {
    return <NotFound />;
  }
  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Equipments Detail</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("təchizat")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("təchizat")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        {style && (
          <section className={style.equipment}>
            <div className={`container ${style.equipment__container}`}>
              <div className={`row ${style.equipment__container__row}`}>
                <div
                  className={`col-lg-6 col-md-6 col-sm-12 ${style.equipment__container__row__col}`}
                >
                  <div style={{ width: "80%", margin: "0 auto" }}>
                    <Slider {...settings}>
                      {equipment.images &&
                        equipment.images.map((image, index) => (
                          <div
                            style={{
                              width: "100%",
                            }}
                            key={index}
                          >
                            <img
                              className={style.image}
                              src={`${IMAGE_URL}${image?.filePath}`}
                              alt={image?.fileName}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
                <div
                  className={`col-lg-6 col-md-6 col-sm-12 ${style.equipment__container__row__col}`}
                >
                  <div
                    className={style.equipment__container__row__col__wrapper}
                  >
                    <div
                      className={
                        style.equipment__container__row__col__wrapper__title
                      }
                    >
                      <h2
                        className={
                          style.equipment__container__row__col__wrapper__title__h2
                        }
                      >
                        {equipment.title}
                      </h2>
                    </div>
                    <div
                      className={
                        style.equipment__container__row__col__wrapper__code
                      }
                    >
                      <p
                        className={
                          style.equipment__container__row__col__wrapper__code__p
                        }
                      >
                        {t("məhsulun kodu")}
                      </p>
                      <span
                        className={
                          style.equipment__container__row__col__wrapper__code__span
                        }
                      >
                        {equipment.productCode}
                      </span>
                    </div>
                    <div
                      className={
                        style.equipment__container__row__col__wrapper__desc
                      }
                    >
                      <p
                        className={
                          style.equipment__container__row__col__wrapper__desc__p
                        }
                      >
                        {t("məhsulun təsviri")}
                      </p>
                      <span
                        className={
                          style.equipment__container__row__col__wrapper__desc__span
                        }
                      >
                        {ReactHtmlParser(equipment?.description)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    )
  );
};

export default EquipmentsDetail;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        // padding: "20px",
        display: "flex",
        backgroundColor: "transparent",
        color: "transparent",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          color: "#666",
          margin: "0",
          position: "relative",
          right: "10px",
          fontSize: "50px",
          fontWeight: "200",
        }}
        onClick={onClick}
      >
        {">"}
      </h1>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        padding: "20px",
        display: "flex",
        backgroundColor: "#f5f4f9",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          color: "#666",
          margin: "0",
          position: "relative",
          right: "20px",
          fontSize: "50px",
          fontWeight: "200",
        }}
        onClick={onClick}
      >
        {"<"}
      </h1>
    </div>
  );
}
