import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import style from "../assets/css/trainingsdetail.module.scss";
import Shape from "../components/Shape";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { GoClock } from "react-icons/go";
import FeaturedTrainings from "../components/FeaturedTrainings";
import { IMAGE_URL } from "../constants";
import LoadingBox from "../components/LoadingBox";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleTraining,
  getSingleError,
  getSingleStatus,
  getSingleTraining,
} from "../features/trainingsSlice";
import NotFound from "./NotFound";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const TrainingsDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  const training = useSelector(getSingleTraining);
  const status = useSelector(getSingleStatus);
  const error = useSelector(getSingleError);

  useEffect(() => {
    dispatch(fetchSingleTraining({ id }));
  }, [dispatch, id]);

  if (status === "loading") {
    return <LoadingBox />;
  }

  if (error.code === "ERR_BAD_REQUEST") {
    return <NotFound />;
  }

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Trainings Detail</title>
        </Helmet>
        <div className={style.headerdown}>
          <div className={style.headerdown__wrapper}>
            <div
              className={`container ${style.headerdown__wrapper__container}`}
            >
              <div
                className={`row ${style.headerdown__wrapper__container__row}`}
              >
                <div
                  className={`col-lg-8 ${style.headerdown__wrapper__container__row__col8}`}
                >
                  <h1
                    className={
                      style.headerdown__wrapper__container__row__col8__h1
                    }
                  >
                    {training.title}
                  </h1>
                  <p
                    className={
                      style.headerdown__wrapper__container__row__col8__p
                    }
                  >
                    {training.subTitle}
                  </p>
                  <Link
                    to="/contact"
                    className={
                      style.headerdown__wrapper__container__row__col8__link
                    }
                  >
                    {t("sertifikatı əldə edin")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.detail}>
          <div className={`container ${style.detail__container}`}>
            <div className={`row ${style.detail__container__row}`}>
              <div className={`col-lg-8 ${style.detail__container__row__col8}`}>
                <div className={style.detail__container__row__col8__content}>
                  <p className={style.detail__container__row__col8__content__p}>
                    {ReactHtmlParser(training?.information)}
                  </p>
                </div>
                <div className={style.detail__container__row__col8__links}>
                  <ul
                    className={style.detail__container__row__col8__links__list}
                  >
                    <li
                      className={
                        style.detail__container__row__col8__links__list__item
                      }
                    >
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://archicoacademy.az/trainings/${id}`}
                        className={
                          style.detail__container__row__col8__links__list__item__link
                        }
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li
                      className={
                        style.detail__container__row__col8__links__list__item
                      }
                    >
                      <a
                        href={`https://twitter.com/share?url=https://archicoacademy.az/trainings/${id}`}
                        className={
                          style.detail__container__row__col8__links__list__item__link
                        }
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li
                      className={
                        style.detail__container__row__col8__links__list__item
                      }
                    >
                      <a
                        href={`mailto:?subject=Check%20this%20https://archicoacademy.az/trainings/${id}`}
                        className={
                          style.detail__container__row__col8__links__list__item__link
                        }
                      >
                        <GrMail />
                      </a>
                    </li>
                    <li
                      className={
                        style.detail__container__row__col8__links__list__item
                      }
                    >
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://archicoacademy.az/trainings/${id}`}
                        className={
                          style.detail__container__row__col8__links__list__item__link
                        }
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li
                      className={
                        style.detail__container__row__col8__links__list__item
                      }
                    >
                      <a
                        href={`https://api.whatsapp.com/send?text=https://archicoacademy.az/trainings/${id}`}
                        className={
                          style.detail__container__row__col8__links__list__item__link
                        }
                      >
                        <FaWhatsapp />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`col-lg-4 ${style.detail__container__row__col4}`}>
                <div className={style.detail__container__row__col4__card}>
                  <img
                    className={style.detail__container__row__col4__card__img}
                    src={`${IMAGE_URL}${training?.image?.filePath}`}
                    alt=""
                  />
                  <div
                    className={
                      style.detail__container__row__col4__card__duration
                    }
                  >
                    <GoClock
                      className={
                        style.detail__container__row__col4__card__duration__icon
                      }
                    />
                    <p
                      className={
                        style.detail__container__row__col4__card__duration__p
                      }
                    >
                      {training.durationTime}
                    </p>
                  </div>
                </div>
                <FeaturedTrainings />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default TrainingsDetail;
