import { Link } from "react-router-dom";
import React from "react";
import style from "../../assets/css/footer.module.scss";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { BiChevronRight } from "react-icons/bi";
import BacktoTop from "./BacktoTop";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccreditations,
  getAll,
  getStatus,
} from "../../features/accreditationsSlice";
import { useEffect } from "react";
import LoadingBox from "../LoadingBox";
import { BsThreeDots, BsXLg } from "react-icons/bs";
import { BASE_URL, IMAGE_URL } from "../../constants";
import axios from "axios";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const [length, setLength] = useState(false);
  const dispatch = useDispatch();
  const accreditations = useSelector(getAll);
  const status = useSelector(getStatus);

  const [logoWhite, setLogoWhite] = useState([]);
  const [facebookUrl, setFacebookUrl] = useState([]);
  const [linkedinUrl, setLinkedinUrl] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const [instagramUrl, setInstagramUrl] = useState([]);
  const [addres, setAddres] = useState([]);
  const [addresLink, setAddresLink] = useState([]);
  const [email, setEmail] = useState([]);
  const [tel, setTel] = useState([]);
  const [catalog, setCatlog] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const logoW = await fetchSettingsData("logo-white");
      const facebook = await fetchSettingsData("facebookUrl");
      const linkedin = await fetchSettingsData("linkedinUrl");
      const youtube = await fetchSettingsData("youtubeUrl");
      const instagram = await fetchSettingsData("instagramUrl");
      const addresData = await fetchSettingsData("addres");
      const addresLinkData = await fetchSettingsData("addresLink");
      const emailData = await fetchSettingsData("email");
      const telData = await fetchSettingsData("tel");
      const catalogData = await fetchSettingsData("catalog");
      setLogoWhite(logoW);
      setFacebookUrl(facebook);
      setLinkedinUrl(linkedin);
      setYoutubeUrl(youtube);
      setInstagramUrl(instagram);
      setAddres(addresData);
      setAddresLink(addresLinkData);
      setEmail(emailData);
      setTel(telData);
      setCatlog(catalogData);
    };
    getSettingsData();
  }, []);

  useEffect(() => {
    dispatch(fetchAccreditations());
  }, [dispatch]);
  if (
    status === "loading" ||
    logoWhite.length === 0 ||
    addres.length === 0 ||
    email.length === 0 ||
    tel.length === 0
  )
    return <LoadingBox />;

  return (
    style && (
      <footer className={style.footer}>
        <section className={style.footer__first}>
          <div className={`container ${style.footer__first__container}`}>
            <div className={`row ${style.footer__first__container__row}`}>
              <div
                className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.footer__first__container__row__col_main}`}
              >
                <ul
                  className={
                    style.footer__first__container__row__col_main__list
                  }
                >
                  <li
                    className={
                      style.footer__first__container__row__col_main__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col_main__list__item__link
                      }
                      href="/"
                    >
                      <img
                        className={
                          style.footer__first__container__row__col_main__list__item__link__image
                        }
                        src={
                          logoWhite &&
                          logoWhite.map((s) => {
                            return IMAGE_URL + s.image.filePath;
                          })
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col_main__list__item
                    }
                  >
                    <a
                      className={
                        style.footer__first__container__row__col_main__list__item__link
                      }
                      href={
                        addresLink &&
                        addresLink.map((s) => {
                          return s.value;
                        })
                      }
                    >
                      {addres &&
                        addres.map((s) => {
                          return s.value;
                        })}
                    </a>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col_main__list__item
                    }
                  >
                    <a
                      className={
                        style.footer__first__container__row__col_main__list__item__link
                      }
                      href={
                        email &&
                        email.map((s) => {
                          return "mailto:" + s.value;
                        })
                      }
                    >
                      {email &&
                        email.map((s) => {
                          return s.value;
                        })}
                    </a>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col_main__list__item
                    }
                  >
                    <a
                      className={
                        style.footer__first__container__row__col_main__list__item__link
                      }
                      href={
                        tel &&
                        tel.map((s) => {
                          return "tel:" + s.value;
                        })
                      }
                    >
                      {tel &&
                        tel.map((s) => {
                          return s.value;
                        })}
                    </a>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col_main__list__item
                    }
                  >
                    <ul
                      className={
                        style.footer__first__container__row__col_main__list__item__list
                      }
                    >
                      <li
                        className={
                          style.footer__first__container__row__col_main__list__item__list__item
                        }
                      >
                        <a
                          className={
                            style.footer__first__container__row__col_main__list__item__list__item__link
                          }
                          href={
                            facebookUrl &&
                            facebookUrl.map((s) => {
                              return s.value;
                            })
                          }
                        >
                          <FaFacebookF />
                        </a>
                      </li>
                      <li
                        className={
                          style.footer__first__container__row__col_main__list__item__list__item
                        }
                      >
                        <a
                          className={
                            style.footer__first__container__row__col_main__list__item__list__item__link
                          }
                          href={
                            linkedinUrl &&
                            linkedinUrl.map((s) => {
                              return s.value;
                            })
                          }
                        >
                          <FaLinkedinIn />
                        </a>
                      </li>
                      <li
                        className={
                          style.footer__first__container__row__col_main__list__item__list__item
                        }
                      >
                        <a
                          className={
                            style.footer__first__container__row__col_main__list__item__list__item__link
                          }
                          href={
                            youtubeUrl &&
                            youtubeUrl.map((s) => {
                              return s.value;
                            })
                          }
                        >
                          <FaYoutube />
                        </a>
                      </li>
                      <li
                        className={
                          style.footer__first__container__row__col_main__list__item__list__item
                        }
                      >
                        <a
                          className={
                            style.footer__first__container__row__col_main__list__item__list__item__link
                          }
                          href={
                            instagramUrl &&
                            instagramUrl.map((s) => {
                              return s.value;
                            })
                          }
                        >
                          <FaInstagram />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div
                className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.footer__first__container__row__col}`}
              >
                <h6
                  className={style.footer__first__container__row__col__heading}
                >
                  {t("akkreditasiyalar")}
                </h6>
                <ul className={style.footer__first__container__row__col__list}>
                  {accreditations &&
                    accreditations.map((accreditation, index) => (
                      <li
                        key={index}
                        className={
                          style.footer__first__container__row__col__list__item
                        }
                      >
                        <div
                          className={
                            style.footer__first__container__row__col__list__item__right
                          }
                        >
                          <BiChevronRight />
                        </div>
                        <Link
                          className={
                            style.footer__first__container__row__col__list__item__link
                          }
                          to="/accreditations"
                        >
                          {!length
                            ? accreditation.description.slice(0, 40)
                            : accreditation.description}
                        </Link>
                        {accreditation.description.length > 40 ? (
                          <button
                            className={
                              style.footer__first__container__row__col__list__item__button
                            }
                            onClick={() => {
                              setLength(!length);
                            }}
                          >
                            {!length ? <BsThreeDots /> : <BsXLg />}
                          </button>
                        ) : (
                          <div></div>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div
                className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.footer__first__container__row__col}`}
              >
                <h6
                  className={style.footer__first__container__row__col__heading}
                >
                  {t("sürətli keçidlər")}
                </h6>
                <ul className={style.footer__first__container__row__col__list}>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/"
                    >
                      <BiChevronRight />
                      {t("ana səhifə")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/about"
                    >
                      <BiChevronRight />
                      {t("haqqında")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/trainings"
                    >
                      <BiChevronRight />
                      {t("təlimlər")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/blogs"
                    >
                      <BiChevronRight />
                      {t("xəbərlər")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/accreditations"
                    >
                      <BiChevronRight />
                      {t("akkreditasiyalar")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/checkcertificat"
                    >
                      <BiChevronRight />
                      {t("sertifikatı yoxla")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      onClick={() => {
                        const fileUrl =
                          catalog &&
                          catalog.map((s) => {
                            return `${IMAGE_URL}${s.image.filePath}`;
                          });
                        const a = document.createElement("a");
                        a.href = fileUrl;
                        a.download = "katalog.pdf";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                      }}
                    >
                      <BiChevronRight />
                      {t("kataloq yüklə")}
                    </Link>
                  </li>
                  <li
                    className={
                      style.footer__first__container__row__col__list__item
                    }
                  >
                    <Link
                      className={
                        style.footer__first__container__row__col__list__item__link
                      }
                      to="/contact"
                    >
                      <BiChevronRight />
                      {t("əlaqə")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className={style.footer__second}>
          <div className={`container ${style.footer__second__container}`}>
            <div className={`row ${style.footer__second__container__row}`}>
              <div className={style.footer__second__container__row__text}>
                Copyright © {new Date().getFullYear()}.{" "}
                <a
                  className={style.footer__second__container__row__text__link}
                  href="https://weblash.az/"
                >
                  Weblash
                </a>
                {"  "}
                {t("komandası tərəfindən hazırlanıb")}
              </div>
            </div>
          </div>
        </section>
        <div style={{ position: "relative", zIndex: "99" }}>
          <BacktoTop />
        </div>
      </footer>
    )
  );
};

export default Footer;
