import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import TrainingsSubCategory from "../components/TrainingsSubCategory";
import style from "../assets/css/trainingssubcategory.module.scss";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../constants";
import axios from "axios";

const TrainingsCategory = () => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const category = Number(query.get("category"));
  const params = useParams();
  const id = Number(params.id);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategory = async () => {
      await axios.get(`${BASE_URL}/categories`).then((res) => {
        setCategories(res?.data?.slice(0, 2));
      });
    };
    getCategory();
  }, []);

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Trainings Category</title>
        </Helmet>
        <HeaderDown>
          <h1>
            {id === categories[0]?.id
              ? categories[0]?.title
              : categories[1]?.title}
          </h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li>
              {" "}
              {id === categories[0]?.id
                ? categories[0]?.title
                : categories[1]?.title}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.sub}>
          <div className="container">
            <TrainingsSubCategory category={id} />
          </div>
        </section>
      </>
    )
  );
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default TrainingsCategory;
