import React from "react";
import { Link } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import style from "../assets/css/checkcertificat.module.scss";
import { useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { BASE_URL, IMAGE_URL } from "../constants";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const CheckCertificat = () => {
  const [open, setOpen] = React.useState(false);
  const [certificate, setCertificate] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t, i18n } = useTranslation();
  const finref = useRef();

  const [checkCertificatImage, setCheckCertificatImage] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const checkCertificatImageData = await fetchSettingsData(
        "checkCertificatImage"
      );
      setCheckCertificatImage(checkCertificatImageData);
    };
    getSettingsData();
  }, []);

  const check = async (fin) => {
    await axios
      .get(`${BASE_URL}/certificates/fin/${fin}`)
      .then((res) => {
        setCertificate(res?.data);
        handleOpen();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("bələ bir sertifikat mövcud deyil"),
        });
      });
  };
  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Check Certificat</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>
            {t("sertifikatı yoxla")}
          </h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>
              {t("sertifikatı yoxla")}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.certificat}>
          <div className={`container ${style.certificat__container}`}>
            <div className={`row ${style.certificat__container__row}`}>
              <div
                className={`col-lg-6 ${style.certificat__container__row__col6}`}
              >
                <div className={style.certificat__container__row__col6__check}>
                  <h3
                    className={
                      style.certificat__container__row__col6__check__h3
                    }
                  >
                    {t("FIN kod")}
                  </h3>
                  <input
                    className={
                      style.certificat__container__row__col6__check__input
                    }
                    type="text"
                    placeholder={`${t("FIN kod")}....`}
                    ref={finref}
                  />
                  <button
                    onClick={() => {
                      check(finref.current.value);
                    }}
                    className={
                      style.certificat__container__row__col6__check__button
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {t("sertifikatı yoxla")}
                  </button>
                  {certificate && (
                    <div>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styleModal}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            {t("sertifikat")}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("ad")}: {certificate?.firstName}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("soyad")}: {certificate?.lastName}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("FIN kod")}: {certificate?.fin}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("təlim")}: {certificate?.education?.title}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("verilmə tarixi")}:{" "}
                            {certificate?.certGivenDate?.slice(0, 10)}
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {t("etibarlıdır")}:{" "}
                            {certificate.certExpiryDate?.slice(0, 10)}
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>{" "}
              <div
                className={`col-lg-6 ${style.certificat__container__row__col6}`}
              >
                <div className={style.certificat__container__row__col6__image}>
                  <img
                    className={
                      style.certificat__container__row__col6__image__img
                    }
                    src={
                      checkCertificatImage &&
                      checkCertificatImage.map((s) => {
                        return `${IMAGE_URL}${s.image.filePath}`;
                      })
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default CheckCertificat;
