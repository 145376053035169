import React from "react";
import { Link, useParams } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import style from "../assets/css/blogsdetail.module.scss";
import FeaturedBlogs from "../components/FeaturedBlogs";
import { AiOutlineCalendar } from "react-icons/ai";
import { useEffect } from "react";
import { IMAGE_URL } from "../constants";
import LoadingBox from "../components/LoadingBox";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "./NotFound";
import { Toaster } from "react-hot-toast";
import {
  fetchSingleBlog,
  getSingleError,
  getSingleBlogs,
  getSingleStatus,
} from "../features/blogSlice";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const BlogsDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const id = Number(params.id);
  const dispatch = useDispatch();
  const blog = useSelector(getSingleBlogs);
  const status = useSelector(getSingleStatus);
  const error = useSelector(getSingleError);
  useEffect(() => {
    dispatch(fetchSingleBlog({ id }));
  }, [dispatch, id]);

  if (error.code === "ERR_BAD_REQUEST") {
    return <NotFound />;
  }
  if (status === "loading") {
    return <LoadingBox />;
  }
  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Blogs Detail</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("xəbərlər")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("xəbərlər")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <div>
          <Toaster />
        </div>
        <section className={style.detail}>
          <div className={`container ${style.detail__container}`}>
            <div className={`row ${style.detail__container__row}`}>
              <div className={`col-lg-9 ${style.detail__container__row__col9}`}>
                {status === "succeeded" ? (
                  <div className={style.detail__container__row__col9__card}>
                    <div
                      className={
                        style.detail__container__row__col9__card__image
                      }
                    >
                      <img
                        className={
                          style.detail__container__row__col9__card__image__img
                        }
                        src={`${IMAGE_URL}${blog && blog.image.filePath}`}
                        alt={blog && blog.image.fileName}
                      />
                    </div>
                    <div
                      className={
                        style.detail__container__row__col9__card__title
                      }
                    >
                      <h2
                        className={
                          style.detail__container__row__col9__card__title__h2
                        }
                      >
                        {blog && blog.title}
                      </h2>
                    </div>
                    <div
                      className={style.detail__container__row__col9__card__date}
                    >
                      <AiOutlineCalendar
                        className={
                          style.detail__container__row__col9__card__date__icon
                        }
                      />
                      <p
                        className={
                          style.detail__container__row__col9__card__date__p
                        }
                      >
                        {blog && blog.createdAt.slice(0, 10)}
                      </p>
                    </div>
                    <div
                      className={style.detail__container__row__col9__card__desc}
                    >
                      <p
                        className={
                          style.detail__container__row__col9__card__desc__p
                        }
                      >
                        {blog && ReactHtmlParser(blog.body)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <FeaturedBlogs page="detail" />
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default BlogsDetail;
