import React, { useState } from "react";
import HeaderDown from "../components/header/HeaderDown";
import { Link, useNavigate } from "react-router-dom";
import Shape from "../components/Shape";
import style from "../assets/css/equipments.module.scss";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Pagination,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEquipments,
  getAllEquipments,
  getEquipmentsPagination,
  getEquipmentsStatus,
} from "../features/equipmentsSlice";
import { useEffect } from "react";
import LoadingBox from "../components/LoadingBox";
import { IMAGE_URL } from "../constants";
import ReactHtmlParser from "react-html-parser";
import {
  fetchEquipmentsSubCategories,
  getAllEquipmentsSubcategories,
  getStatus,
} from "../features/equipmentsSubCategoriesSlice";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Equipments = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedSubId, setSelectedSubId] = useState(null);
  const dispatch = useDispatch();
  const equipments = useSelector(getAllEquipments);
  const pagination = useSelector(getEquipmentsPagination);
  const status = useSelector(getEquipmentsStatus);
  const equipmentSubCategories = useSelector(getAllEquipmentsSubcategories);
  const subStatus = useSelector(getStatus);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  useEffect(() => {
    dispatch(fetchEquipmentsSubCategories());
    dispatch(fetchEquipments({ search, pageNumber, sub: selectedSubId }));
  }, [dispatch, pageNumber, selectedSubId, search]);
  if (status === "loading" || subStatus === "loading") return <LoadingBox />;
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Equipments</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("təchizat")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("təchizat")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.equipments}>
          <div className={`container ${style.equipments__container}`}>
            <div className={`row ${style.equipments__container__row}`}>
              <div
                className={`col-lg-3 col-md-3 col-sm-12 ${style.equipments__container__row__col3}`}
              >
                <div
                  className={style.equipments__container__row__col3__wrapper}
                >
                  <div
                    className={
                      style.equipments__container__row__col3__wrapper__heading
                    }
                  >
                    <h2
                      className={
                        style.equipments__container__row__col3__wrapper__heading__h2
                      }
                    >
                      {t("təchizat categoryası")}
                    </h2>
                  </div>
                  <div
                    className={
                      style.equipments__container__row__col3__wrapper__search
                    }
                  >
                    <input
                      className={
                        style.equipments__container__row__col3__wrapper__search__input
                      }
                      ref={searchRef}
                      type="text"
                      placeholder={`${t("axtar")}...`}
                    />
                    <button
                      onClick={() => {
                        setSearch(searchRef.current.value);
                      }}
                      className={
                        style.equipments__container__row__col3__wrapper__search__button
                      }
                    >
                      {t("axtar")}
                    </button>
                  </div>
                  <div
                    className={
                      style.equipments__container__row__col3__wrapper__categories
                    }
                  >
                    <ul
                      className={
                        style.equipments__container__row__col3__wrapper__categories__list
                      }
                    >
                      {equipmentSubCategories &&
                        equipmentSubCategories.map((sub, index) => (
                          <li
                            key={index}
                            className={
                              style.equipments__container__row__col3__wrapper__categories__list__item
                            }
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedSubId === sub.id}
                                    onChange={(e) => {
                                      setSelectedSubId(sub.id);
                                    }}
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 18 },
                                    }}
                                  />
                                }
                                label={sub.title}
                              />
                            </FormGroup>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-9 col-md-9 col-sm-12 ${style.equipments__container__row__col9}`}
              >
                <div
                  className={`row ${style.equipments__container__row__col9__row}`}
                >
                  {equipments &&
                    equipments.map((equipment, index) => (
                      <div
                        key={index}
                        className={`col-lg-4 col-md-6 col-sm-12 ${style.equipments__container__row__col9__row__col4}`}
                      >
                        <div
                          className={
                            style.equipments__container__row__col9__row__col4__card
                          }
                        >
                          <div
                            className={
                              style.equipments__container__row__col9__row__col4__card__image
                            }
                          >
                            {equipment.images &&
                              equipment.images.map((image, index) =>
                                image.isMain ? (
                                  <img
                                    onClick={() => {
                                      navigate(`/equipments/${equipment.id}`);
                                    }}
                                    key={index}
                                    className={
                                      style.equipments__container__row__col9__row__col4__card__image__img
                                    }
                                    src={`${IMAGE_URL}${image?.filePath}`}
                                    alt={image?.fileName}
                                  />
                                ) : (
                                  ""
                                )
                              )}
                          </div>
                          <div
                            className={
                              style.equipments__container__row__col9__row__col4__card__title
                            }
                          >
                            <h4
                              className={
                                style.equipments__container__row__col9__row__col4__card__title__h4
                              }
                            >
                              {equipment?.title}
                            </h4>
                          </div>
                          <div
                            className={
                              style.equipments__container__row__col9__row__col4__card__link
                            }
                          >
                            <Link
                              className={
                                style.equipments__container__row__col9__row__col4__card__link__link
                              }
                              to={`/equipments/${equipment.id}`}
                            >
                              Daha çox məlumat{">>"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  {equipments.length > 0 ? (
                    <div
                      style={{
                        marginTop: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Stack spacing={2}>
                        <Pagination
                          count={pagination.TotalPages}
                          page={pagination.CurrentPage}
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                          sx={{
                            "& .Mui-selected": {
                              backgroundColor: "#e87812 !important",
                              color: "#fff !important",
                              margin: "0",
                            },
                            "& .MuiPaginationItem-root:hover": {
                              color: "#e87812 !important",
                            },
                            "& .Mui-selected:hover": {
                              backgroundColor: "#e87812 !important",
                              color: "#fff !important",
                            },
                          }}
                        />
                      </Stack>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default Equipments;
