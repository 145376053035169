import React from "react";
import style from "../assets/css/feedbackcard.module.scss";
import { FaQuoteLeft } from "react-icons/fa";
import { IMAGE_URL } from "../constants";

const FeedbackCard = ({ feedback }) => {
  return (
    style && (
      <div className={style.feedbackcard}>
        <div className={style.feedbackcard__card}>
          <span className={style.feedbackcard__card__quote}>
            <FaQuoteLeft />
          </span>
          <p className={style.feedbackcard__card__message}>
            {feedback.comment}
          </p>
          <img
            className={style.feedbackcard__card__logo}
            src={`${IMAGE_URL}${feedback.image.filePath}`}
            alt=""
          />
          <h3 className={style.feedbackcard__card__trainer}>
            {feedback.fullName}
          </h3>
          <span className={style.feedbackcard__card__duty}>
            {feedback.role}
          </span>
        </div>
      </div>
    )
  );
};

export default FeedbackCard;
