import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import style from "../assets/css/blogs.module.scss";
import { AiOutlineCalendar } from "react-icons/ai";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Shape from "../components/Shape";
import FeaturedBlogs from "../components/FeaturedBlogs";
import LoadingBox from "../components/LoadingBox";
import { IMAGE_URL } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBlog,
  getAllBlogs,
  getStatus,
  getPagination,
} from "../features/blogSlice";
import { Toaster } from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Blogs = () => {
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const search = String(query.get("search"));
  const dispatch = useDispatch();
  const blogs = useSelector(getAllBlogs);
  const pagination = useSelector(getPagination);
  const status = useSelector(getStatus);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    dispatch(
      fetchBlog({ search: search === "null" ? "" : search, pageNumber })
    );
  }, [pageNumber, search, dispatch]);

  if (status === "loading") {
    return <LoadingBox />;
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Blogs</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("xəbərlər")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("xəbərlər")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <div>
          <Toaster />
        </div>

        <section className={style.blogs}>
          <div className={`container ${style.blogs__container}`}>
            <div className={`row ${style.blogs__container__row}`}>
              <div className={`col-lg-9 ${style.blogs__container__row__col9}`}>
                <div
                  className={`row ${style.blogs__container__row__col9__row}`}
                >
                  {status === "succeeded" ? (
                    blogs.map((blog, index) => (
                      <div
                        key={blog.id}
                        className={`col-lg-12 ${style.blogs__container__row__col9__row__col12}`}
                      >
                        <div
                          className={`row ${style.blogs__container__row__col9__row__col12__row}`}
                        >
                          <div
                            className={`col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 ${style.blogs__container__row__col9__row__col12__row__col4}`}
                          >
                            <div
                              className={
                                style.blogs__container__row__col9__row__col12__row__col4__image
                              }
                            >
                              <Link to={`/blogs/${blog.id}`}>
                                <img
                                  className={
                                    style.blogs__container__row__col9__row__col12__row__col4__image__img
                                  }
                                  src={`${IMAGE_URL}${blog.image.filePath}`}
                                  alt={blog.image.fileName}
                                />
                              </Link>
                            </div>
                          </div>
                          <div
                            className={`col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 ${style.blogs__container__row__col9__row__col12__row__col8}`}
                          >
                            <div
                              className={
                                style.blogs__container__row__col9__row__col12__row__col8__card
                              }
                            >
                              <Link
                                className={
                                  style.blogs__container__row__col9__row__col12__row__col8__card__link
                                }
                                to={`/blogs/${blog.id}`}
                              >
                                <h3
                                  className={
                                    style.blogs__container__row__col9__row__col12__row__col8__card__link__h3
                                  }
                                >
                                  {blog.title}
                                </h3>
                              </Link>
                              <p
                                className={
                                  style.blogs__container__row__col9__row__col12__row__col8__card__p
                                }
                              >
                                {ReactHtmlParser(
                                  blog.body.length > 200
                                    ? `${blog.body.slice(0, 200)}....`
                                    : blog.body
                                )}
                              </p>
                              <span
                                className={
                                  style.blogs__container__row__col9__row__col12__row__col8__card__span
                                }
                              >
                                <AiOutlineCalendar />{" "}
                                {blog.createdAt.slice(0, 10)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
                {blogs.length > 0 ? (
                  <div
                    className={style.blogs__container__row__col9__pagination}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pagination.TotalPages}
                        page={pagination.CurrentPage}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          "& .Mui-selected": {
                            backgroundColor: "#e87812 !important",
                            color: "#fff !important",
                            margin: "0",
                          },
                          "& .MuiPaginationItem-root:hover": {
                            color: "#e87812 !important",
                          },
                          "& .Mui-selected:hover": {
                            backgroundColor: "#e87812 !important",
                            color: "#fff !important",
                          },
                        }}
                      />
                    </Stack>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <FeaturedBlogs />
            </div>
          </div>
        </section>
      </>
    )
  );
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default Blogs;
