import React, { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import style from "../assets/css/featuredtrainings.module.scss";
import LoadingBox from "./LoadingBox";
import {
  fetchFeaturedTrainings,
  getFeaturedStatus,
  getFeaturedTraining,
} from "../features/trainingsSlice";
import { IMAGE_URL } from "../constants";
import { useTranslation } from "react-i18next";

const FeaturedTrainings = () => {
  const { t, i18n } = useTranslation();
  const searchRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trainings = useSelector(getFeaturedTraining);
  const status = useSelector(getFeaturedStatus);

  useEffect(() => {
    dispatch(fetchFeaturedTrainings());
  }, [dispatch]);

  if (status === "loading") return <LoadingBox />;

  return (
    style && (
      <>
        <div className={style.search}>
          <input
            className={style.search__input}
            type="text"
            placeholder={`${t("təlimi axtar")}...`}
            ref={searchRef}
          />

          <button
            onClick={() => {
              navigate(`/trainings?search=${searchRef.current.value}`);
            }}
            className={style.search__button}
          >
            {t("axtar")}
          </button>
        </div>
        <div className={style.selected}>
          <div className={style.selected__title}>
            <h3 className={style.selected__title__h3}>
              {t("seçilmiş təlimlər")}
            </h3>
          </div>
          <div className={style.selected__list}>
            {trainings &&
              trainings.map((training, index) => (
                <div key={index} className={style.selected__list__card}>
                  <div className={style.selected__list__card__image}>
                    <Link to={`/trainings/${training.id}`}>
                      <img
                        className={style.selected__list__card__image__img}
                        src={`${IMAGE_URL}${training.image.filePath}`}
                        alt={training.image.fileName}
                      />
                    </Link>
                  </div>
                  <div className={style.selected__list__card__body}>
                    <Link
                      className={style.selected__list__card__body__link}
                      to={`/trainings/${training.id}`}
                    >
                      {training.title}
                    </Link>
                    <span className={style.selected__list__card__body__span}>
                      {training.durationTime}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    )
  );
};

export default FeaturedTrainings;
