import style from "../assets/css/about.module.scss";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import UserCount from "../components/UserCount";
import texnikibazaImage from "../assets/images/texnikibaza.png";
import sertifikatImage from "../assets/images/sertifikatChoseus.png";
import telimciImage from "../assets/images/telimci.png";
import xidmetImage from "../assets/images/xidmet.png";
import Shape from "../components/Shape";
import { useEffect } from "react";
import { BASE_URL, IMAGE_URL } from "../constants";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import LoadingBox from "../components/LoadingBox";

const About = () => {
  const { t, i18n } = useTranslation();

  const [about, setAbout] = useState([]);
  const [aboutImage, setAboutImage] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const aboutData = await fetchSettingsData("about");
      const aboutImageData = await fetchSettingsData("aboutImage");
      setAbout(aboutData);
      setAboutImage(aboutImageData);
    };
    getSettingsData();
  }, []);
  if (about.length === 0 || aboutImage === 0) return <LoadingBox />;

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | About</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("haqqında")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("haqqında")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.about}>
          <div className={`container ${style.about__container}`}>
            <h1 className={style.about__container__title}>{t("haqqımızda")}</h1>
            <p className={style.about__container__sub_title}>
              {t(
                "inşaat bizim üçün bir işdən daha artıqdır. biz inşaatın özüyük.."
              )}
            </p>
            <p className={style.about__container__desc}>
              {about &&
                about.map((s) => {
                  return ReactHtmlParser(s.value);
                })}
            </p>
          </div>
        </section>
        <section className={style.usercount}>
          <UserCount bgColor="rgba(21, 17, 17, 0.4)" txtColor="#fff" />
        </section>
        <section className={style.chooseus}>
          <div className={`container ${style.chooseus__container}`}>
            <div className={style.chooseus__container__title}>
              <h2 className={style.chooseus__container__title__h2}>
                {t("niyə bizi seçməlisiniz")}
              </h2>
              <p className={style.chooseus__container__title__p}>
                {t("bizi digərlərindən fərqləndirən özəlliklərimiz")}
              </p>
            </div>
            <div className={`row ${style.chooseus__container__row}`}>
              <div
                className={`col-lg-7 col-md-12 col-sm-12 col-12 ${style.chooseus__container__row__col7}`}
              >
                <div
                  className={`row ${style.chooseus__container__row__col7__row}`}
                >
                  <div
                    className={`col-lg-6 ${style.chooseus__container__row__col7__row__col}`}
                  >
                    <div
                      className={
                        style.chooseus__container__row__col7__row__col__card
                      }
                    >
                      <div
                        className={
                          style.chooseus__container__row__col7__row__col__card__card_body
                        }
                      >
                        <div
                          className={`bg-success ${style.chooseus__container__row__col7__row__col__card__card_body__image}`}
                        >
                          <img
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__image__img
                            }
                            src={texnikibazaImage}
                            alt=""
                          />
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__title
                          }
                        >
                          <h3
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__title__h3
                            }
                          >
                            {t("maddi texniki baza")}
                          </h3>
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__desc
                          }
                        >
                          <p
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__desc__p
                            }
                          >
                            {t(
                              "azərbaycandakı ən müasir, geniş və dünya standartlarına cavab verən texniki bazaya sahibik."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        style.chooseus__container__row__col7__row__col__card
                      }
                    >
                      <div
                        className={
                          style.chooseus__container__row__col7__row__col__card__card_body
                        }
                      >
                        <div
                          className={`bg-primary ${style.chooseus__container__row__col7__row__col__card__card_body__image}`}
                        >
                          <img
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__image__img
                            }
                            src={telimciImage}
                            alt=""
                          />
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__title
                          }
                        >
                          <h3
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__title__h3
                            }
                          >
                            {t("peşəkar təlimçilər")}
                          </h3>
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__desc
                          }
                        >
                          <p
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__desc__p
                            }
                          >
                            {t(
                              "təlimlərimiz həm teorik, həm də praktiki təcrübəyə sahib, beynəlxalq sertifikatlar almış mütəxəssislər tərəfindən tədris olunur."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-lg-6 ${style.chooseus__container__row__col7__row__col}`}
                  >
                    <div
                      className={
                        style.chooseus__container__row__col7__row__col__card
                      }
                    >
                      <div
                        className={
                          style.chooseus__container__row__col7__row__col__card__card_body
                        }
                      >
                        <div
                          className={`bg-warning ${style.chooseus__container__row__col7__row__col__card__card_body__image}`}
                        >
                          <img
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__image__img
                            }
                            src={sertifikatImage}
                            alt=""
                          />
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__title
                          }
                        >
                          <h3
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__title__h3
                            }
                          >
                            {t("etibarlı sertifikat")}
                          </h3>
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__desc
                          }
                        >
                          <p
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__desc__p
                            }
                          >
                            {t(
                              "müəssisəmizin verdiyi sertifikat beynəlxalq statusa malik olub təkcə Azərbaycanda deyil, dünyanın əksər ölkələrində tanınır."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        style.chooseus__container__row__col7__row__col__card
                      }
                    >
                      <div
                        className={
                          style.chooseus__container__row__col7__row__col__card__card_body
                        }
                      >
                        <div
                          className={`bg-danger ${style.chooseus__container__row__col7__row__col__card__card_body__image}`}
                        >
                          <img
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__image__img
                            }
                            src={xidmetImage}
                            alt=""
                          />
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__title
                          }
                        >
                          <h3
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__title__h3
                            }
                          >
                            {t("operativ xidmət")}
                          </h3>
                        </div>
                        <div
                          className={
                            style.chooseus__container__row__col7__row__col__card__card_body__desc
                          }
                        >
                          <p
                            className={
                              style.chooseus__container__row__col7__row__col__card__card_body__desc__p
                            }
                          >
                            {t(
                              "keyfiyyət şirkətimizin əsas prinsipi olduğu üçün operativlik siyasətimizdə prioritet təşkil edir."
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-5 ${style.chooseus__container__row__col5}`}
              >
                <div className={style.chooseus__container__row__col5__image}>
                  <img
                    className={style.chooseus__container__row__col5__image__img}
                    src={
                      aboutImage &&
                      aboutImage.map((s) => {
                        return `${IMAGE_URL}${s.image.filePath}`;
                      })
                    }
                    alt="aboutImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default About;
