import React from "react";
import style from "../assets/css/shape.module.scss";

const Shape = ({ bgColor }) => {
  return (
    style && (
      <div className={style.relative}>
        <div className={`overflow-hidden text-white ${style.relative__shape}`}>
          <svg
            className={style.relative__shape__svg}
            viewBox="0 0 2880 48"
            fill="none"
            xmsns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill={bgColor}
            ></path>
          </svg>
        </div>
      </div>
    )
  );
};

export default Shape;
