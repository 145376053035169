import React, { useEffect, useRef } from "react";
import style from "../../assets/css/header.module.scss";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import azFlag from "../../assets/images/Flag_of_Azerbaijan.svg.png";
import enFlag from "../../assets/images/Flag_of_the_United_Kingdom_(3-5).svg.png";
import ruFlag from "../../assets/images/Flag_of_Russia.svg.webp";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import MenuDrawer from "./MenuDrawer";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL, IMAGE_URL } from "../../constants";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import LoadingBox from "../LoadingBox";

const Header = ({ sticky }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const [categories, setCategories] = useState([]);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [logoWhite, setLogoWhite] = useState([]);
  const [logoColored, setLogoColored] = useState([]);
  const [facebookUrl, setFacebookUrl] = useState([]);
  const [linkedinUrl, setLinkedinUrl] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const [instagramUrl, setInstagramUrl] = useState([]);
  const [tel, setTel] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const logoW = await fetchSettingsData("logo-white");
      const logoC = await fetchSettingsData("logo-colored");
      const facebook = await fetchSettingsData("facebookUrl");
      const linkedin = await fetchSettingsData("linkedinUrl");
      const youtube = await fetchSettingsData("youtubeUrl");
      const instagram = await fetchSettingsData("instagramUrl");
      const telData = await fetchSettingsData("tel");
      setLogoWhite(logoW);
      setLogoColored(logoC);
      setFacebookUrl(facebook);
      setLinkedinUrl(linkedin);
      setYoutubeUrl(youtube);
      setInstagramUrl(instagram);
      setTel(telData);
    };
    getSettingsData();
  }, []);
  useEffect(() => {
    const getCategory = async () => {
      await axios
        .get(`${BASE_URL}/categories`)
        .then((res) => {
          setCategories(res.data?.slice(0, 2));
        })
        .catch((err) => {});
    };
    getCategory();
  }, [dispatch]);
  const getLanguage = async (lang) => {
    await axios
      .post(`${BASE_URL}/languages/${lang}`, {})
      .then((res) => {
        toast.success("dil dəyişdirildi");
        navigate("/");
        window.location.reload(false);
      })
      .catch((err) => {
        toast.error("Belə bir dil mövcud deyil");
      });
  };
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    getLanguage(lang);
  };
  if (logoWhite.length === 0 || logoColored.length === 0 || tel.length === 0) {
    return <LoadingBox />;
  }
  return (
    <header
      className={style.header}
      onClick={(e) => {
        e.stopPropagation();
        setDropdown(false);
      }}
    >
      <section className={style.header__first}>
        <div className={`container ${style.container}`}>
          <div className={`row ${style.row}`}>
            <div
              className={`col-lg-8 col-md-8 col-sm-8 col-xs-12 ${style.col_8}`}
            >
              <div className={`${style.flex}`}>
                <div className={`${style.social_links}`}>
                  <ul className={`flex ${style.flex}`}>
                    <li className={`list_item ${style.list_item}`}>
                      <a
                        href={
                          facebookUrl &&
                          facebookUrl.map((s) => {
                            return s.value;
                          })
                        }
                        className={`${style.list_item_link}`}
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li className={`list_item ${style.list_item}`}>
                      <a
                        href={
                          linkedinUrl &&
                          linkedinUrl.map((s) => {
                            return s.value;
                          })
                        }
                        className={`${style.list_item_link}`}
                      >
                        <FaLinkedinIn />
                      </a>
                    </li>
                    <li className={`list_item ${style.list_item}`}>
                      <a
                        href={
                          youtubeUrl &&
                          youtubeUrl.map((s) => {
                            return s.value;
                          })
                        }
                        className={`${style.list_item_link}`}
                      >
                        <FaYoutube />
                      </a>
                    </li>
                    <li className={`list_item ${style.list_item}`}>
                      <a
                        href={
                          instagramUrl &&
                          instagramUrl.map((s) => {
                            return s.value;
                          })
                        }
                        className={`list_item_link ${style.list_item_link}`}
                      >
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={style.flag}>
                  <ul className={style.flag__list}>
                    <li className={style.flag__list__item}>
                      <button
                        className={style.flag__list__item__link}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdown(!dropdown);
                        }}
                      >
                        <img
                          src={
                            i18n.language === "az-Latn"
                              ? azFlag
                              : i18n.language === "en-US"
                              ? enFlag
                              : i18n.language === "ru"
                              ? ruFlag
                              : enFlag
                          }
                          alt="flag"
                          className={style.flag__list__item__link__image}
                        />
                        <span className={style.flag__list__item__link__text}>
                          {i18n.language === "az-Latn"
                            ? "az"
                            : i18n.language === "en-US"
                            ? "en"
                            : i18n.language === "ru"
                            ? "ru"
                            : "az"}
                        </span>
                      </button>
                      <div
                        className={` ${dropdown ? "d-block" : "d-none"} ${
                          style.flag__list__item__dropdown
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropdown(true);
                        }}
                      >
                        <ul className={style.flag__list__item__dropdown__list}>
                          <li
                            className={
                              style.flag__list__item__dropdown__list__item
                            }
                            onClick={() => {
                              changeLanguage("az-Latn");
                            }}
                          >
                            <a
                              className={
                                style.flag__list__item__dropdown__list__item__links
                              }
                            >
                              <img
                                className={
                                  style.flag__list__item__dropdown__list__item__links__image
                                }
                                src={azFlag}
                                alt="Azerbaijan flag"
                              />
                              <span
                                className={
                                  style.flag__list__item__dropdown__list__item__links__text
                                }
                              >
                                az
                              </span>
                            </a>
                          </li>
                          <li
                            className={
                              style.flag__list__item__dropdown__list__item
                            }
                            onClick={() => {
                              changeLanguage("en-US");
                            }}
                          >
                            <a
                              className={
                                style.flag__list__item__dropdown__list__item__links
                              }
                            >
                              <img
                                className={
                                  style.flag__list__item__dropdown__list__item__links__image
                                }
                                src={enFlag}
                                alt="English flag"
                              />
                              <span
                                className={
                                  style.flag__list__item__dropdown__list__item__links__text
                                }
                              >
                                en
                              </span>
                            </a>
                          </li>
                          <li
                            className={
                              style.flag__list__item__dropdown__list__item
                            }
                            onClick={() => {
                              changeLanguage("ru");
                            }}
                          >
                            <a
                              className={
                                style.flag__list__item__dropdown__list__item__links
                              }
                            >
                              <img
                                className={
                                  style.flag__list__item__dropdown__list__item__links__image
                                }
                                src={ruFlag}
                                alt="Russia flag"
                              />
                              <span
                                className={
                                  style.flag__list__item__dropdown__list__item__links__text
                                }
                              >
                                ru
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={
          sticky && windowSize.current[0] > 992
            ? {
                backgroundColor: "#fff",
                position: "fixed",
                top: "0",
                width: "100%",
                transition: "100ms all linear",
              }
            : windowSize.current[0] < 992 && sticky
            ? {
                backgroundColor: "#e87813",
                position: "fixed",
                top: "0",
                width: "100%",
                transition: "100ms all linear",
              }
            : windowSize.current[0] < 992 && !sticky
            ? {
                backgroundColor: "transparent",
              }
            : {}
        }
        className={style.header__second}
      >
        <div className={`container ${style.header__second__custom__container}`}>
          <div
            className={`row ${style.header__second__custom__container__custom__row}`}
          >
            <div
              className={`col-md-4 col-sm-4 col-4 ${style.header__second__custom__container__custom__row__col_menu}`}
            >
              <MenuDrawer />
            </div>
            <div
              className={`col-lg-2 col-md-4 col-sm-4 col-4 ${style.header__second__custom__container__custom__row__col_2}`}
            >
              <Link
                to="/"
                className={
                  style.header__second__custom__container__custom__row__col_2__link
                }
              >
                <img
                  className={
                    style.header__second__custom__container__custom__row__col_2__link__image
                  }
                  src={
                    sticky && windowSize.current[0] > 992
                      ? logoColored.length > 0 &&
                        logoColored.map((logo) => {
                          return IMAGE_URL + logo?.image?.filePath;
                        })
                      : logoWhite.length > 0 &&
                        logoWhite.map((logo, index) => {
                          return IMAGE_URL + logo?.image?.filePath;
                        })
                  }
                  alt="archico logo"
                />
              </Link>
            </div>
            <div
              className={`col-md-4 col-sm-4 col-4 ${style.header__second__custom__container__custom__row__col_tel}`}
            >
              <a
                className={
                  style.header__second__custom__container__custom__row__col_tel__link
                }
                href={
                  tel &&
                  tel.map((s) => {
                    return "tel:" + s.value;
                  })
                }
              >
                <LocalPhoneIcon />
              </a>
            </div>
            <div
              className={`col-lg-10 ${style.header__second__custom__container__custom__row__col_10}`}
            >
              <ul
                className={
                  style.header__second__custom__container__custom__row__col_10__list
                }
              >
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/about"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("haqqında")}
                  </Link>
                </li>
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to={`trainings`}
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("təlimlər")}
                  </Link>
                  <div
                    className={`${style.dropdown} ${style.header__second__custom__container__custom__row__col_10__list__item__dropdown}`}
                  >
                    {categories &&
                      categories.map((category, index) => (
                        <Link
                          key={index}
                          className={
                            style.header__second__custom__container__custom__row__col_10__list__item__dropdown__link
                          }
                          to={`trainingscategory/${category.id}`}
                        >
                          {category.title}
                        </Link>
                      ))}
                  </div>
                </li>
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/educationevents"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("təlim cədvəli")}
                  </Link>
                </li>
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/blogs"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("xəbərlər")}
                  </Link>
                </li>
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/accreditations"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("akkreditasiyalar")}
                  </Link>
                </li>
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/checkcertificat"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("sertifikatı yoxla")}
                  </Link>
                </li>
                {/* <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    style={
                      sticky
                        ? {
                            color: "#000",
                          }
                        : { color: "#fff" }
                    }
                    to="/a"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link
                    }
                  >
                    {t("kataloq yüklə")}
                  </Link>
                </li> */}
                <li
                  className={
                    style.header__second__custom__container__custom__row__col_10__list__item
                  }
                >
                  <Link
                    to="/contact"
                    className={
                      style.header__second__custom__container__custom__row__col_10__list__item__link_contact
                    }
                  >
                    {t("əlaqə")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
