import React from "react";
import style from "../../assets/css/headerdown.module.scss";

const HeaderDown = ({ children }) => {
  return (
    <div className={style.headerdown}>
      <div className={style.headerdown__wrapper}>
        <div className={style.headerdown__wrapper__text}>{children}</div>
      </div>
    </div>
  );
};

export default HeaderDown;
