import React from "react";
import style from "../assets/css/ndtservicescard.module.scss";
import { IMAGE_URL } from "../constants";
import { useNavigate } from "react-router-dom";

const NdtServicesCard = ({ engineerService }) => {
  const navigate = useNavigate();
  return (
    style && (
      <div
        onClick={() => {
          navigate(`/engineerservices/${engineerService.id}`);
        }}
        style={{ cursor: "pointer" }}
        className={style.ndtservice}
      >
        <div className={`${style.ndtservice__custom_card}`}>
          <div className={`${style.ndtservice__custom_card__image}`}>
            <img
              className={`${style.ndtservice__custom_card__image__img}`}
              src={`${IMAGE_URL}${engineerService?.image?.filePath}`}
              alt={engineerService?.image?.fileName}
            />
          </div>
          <div className={`${style.ndtservice__custom_card__title}`}>
            <h4 className={`${style.ndtservice__custom_card__title__txt}`}>
              {engineerService.title.length > 30
                ? engineerService.title.slice(0, 30) + "..."
                : engineerService.title}
            </h4>
          </div>
          <div className={`${style.ndtservice__custom_card__desc}`}>
            <p className={`${style.ndtservice__custom_card__desc__txt}`}>
              {engineerService.subTitle.length > 150
                ? engineerService.subTitle.slice(0, 150) + "...."
                : engineerService.subTitle}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default NdtServicesCard;
