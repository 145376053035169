import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import TrainingsSubCategory from "../components/TrainingsSubCategory";
import style from "../assets/css/trainingssubcategory.module.scss";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEngineerServicesSubCategories,
  getAllEnginerServicesSubcategories,
} from "../features/engineerServicesSubCategoriesSlice";
import { IMAGE_URL } from "../constants";
import { Helmet } from "react-helmet";

const EngineerServicesCategory = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const subCategories = useSelector(getAllEnginerServicesSubcategories);
  useEffect(() => {
    dispatch(fetchEngineerServicesSubCategories());
  }, [dispatch]);

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Engineer Services Category</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>
            {t("mühəndislik ximətləri")}
          </h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>
              {t("mühəndislik ximətləri")}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.sub}>
          <div className={`container ${style.sub__container}`}>
            <div className={`row ${style.sub__container__row}`}>
              {subCategories &&
                subCategories.map((subCategory, index) => (
                  <div
                    key={index}
                    className={`col-lg-4 col-md-4 col-sm-12 col-12 ${style.sub__container__row__card}`}
                  >
                    <Link
                      to={`/engineerservices?sub=${subCategory.id}`}
                      className={style.sub__container__row__card__link}
                    >
                      <div
                        className={`card ${style.sub__container__row__card__link__item}`}
                      >
                        <div
                          className={
                            style.sub__container__row__card__link__item__image
                          }
                        >
                          <img
                            className={
                              style.sub__container__row__card__link__item__image__img
                            }
                            src={`${IMAGE_URL}${subCategory?.image?.filePath}`}
                            alt={subCategory?.image?.fileName}
                          />
                        </div>
                        <div
                          className={
                            style.sub__container__row__card__link__item__title
                          }
                        >
                          <p
                            className={
                              style.sub__container__row__card__link__item__title__txt
                            }
                          >
                            {subCategory?.title}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default EngineerServicesCategory;
