import React, { useState } from "react";
import style from "../assets/css/accreditations.module.scss";
import { Link } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccreditations,
  getAll,
  getError,
  getStatus,
} from "../features/accreditationsSlice";
import { useEffect } from "react";
import LoadingBox from "../components/LoadingBox";
import { IMAGE_URL } from "../constants";
import { BsThreeDots, BsXLg } from "react-icons/bs";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Accreditations = () => {
  const { t, i18n } = useTranslation();
  const [length, setLength] = useState(false);
  const dispatch = useDispatch();
  const accreditations = useSelector(getAll);
  const status = useSelector(getStatus);
  const error = useSelector(getError);
  useEffect(() => {
    dispatch(fetchAccreditations());
  }, [dispatch]);
  if (status === "loading") return <LoadingBox />;
  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Accreditations</title>
        </Helmet>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>
            {t("akkreditasiyalar")}
          </h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>
              {t("akkreditasiyalar")}
            </li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        {style && (
          <section className={style.accreditations}>
            <div className={`container ${style.accreditations__container}`}>
              <div className={style.accreditations__container__title}>
                <h1 className={style.accreditations__container__title__h1}>
                  {t("akkreditasiya və lisenziyalar")}
                </h1>
              </div>
              <div className={`row ${style.accreditations__container__row}`}>
                {accreditations &&
                  accreditations.map((accreditation, index) => (
                    <div
                      key={index}
                      className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.accreditations__container__row__col}`}
                    >
                      <div
                        className={
                          style.accreditations__container__row__col__card
                        }
                      >
                        <div
                          className={
                            style.accreditations__container__row__col__card__image
                          }
                        >
                          <img
                            className={
                              style.accreditations__container__row__col__card__image__img
                            }
                            src={`${IMAGE_URL}${accreditation?.image?.filePath}`}
                            alt={accreditation?.image?.fileName}
                          />
                        </div>
                        <div
                          className={
                            style.accreditations__container__row__col__card__name
                          }
                        >
                          <h4
                            className={
                              style.accreditations__container__row__col__card__name__h4
                            }
                          >
                            {!length
                              ? accreditation?.description?.slice(0, 35)
                              : accreditation?.description}
                          </h4>
                          {accreditation?.description?.length > 35 ? (
                            <button
                              onClick={() => {
                                setLength(!length);
                              }}
                              className={
                                style.accreditations__container__row__col__card__name__button
                              }
                            >
                              {!length ? <BsThreeDots /> : <BsXLg />}
                            </button>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
        )}
      </>
    )
  );
};

export default Accreditations;
