import React from "react";
import { Link } from "react-router-dom";
import HeaderDown from "../components/header/HeaderDown";
import Shape from "../components/Shape";
import style from "../assets/css/contact.module.scss";
import { ImPhone } from "react-icons/im";
import { MdLocationOn } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { Formik } from "formik";
import axios from "axios";
import { BASE_URL } from "../constants";
import { toast, Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Contact = () => {
  const { t, i18n } = useTranslation();

  const [tel, setTel] = useState([]);
  const [addres, setAddres] = useState([]);
  const [email, setEmail] = useState([]);
  const fetchSettingsData = async (key) => {
    const response = await axios.get(
      `${BASE_URL}/settings?key=${key}&pageSize=1000`
    );
    return response.data;
  };
  useEffect(() => {
    const getSettingsData = async () => {
      const telData = await fetchSettingsData("tel");
      const addresData = await fetchSettingsData("addres");
      const emailData = await fetchSettingsData("email");
      setTel(telData);
      setAddres(addresData);
      setEmail(emailData);
    };
    getSettingsData();
  }, []);

  const contactFormsPost = async (values) => {
    await axios
      .post(`${BASE_URL}/contactusforms`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        toast.success("Mesaj göndərildi");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 429) {
            toast.error(
              t(
                "həddindən artıq sorğu. Saatda maksimum 3 sorğu göndərə bilərsiniz"
              )
            );
          } else if (error.response.status === 400) {
            toast.error(t("zəhmət olmasa dataları düzgün daxil edin"));
          } else {
            alert("An error occurred while sending the request: ", error);
          }
        }
      });
  };

  return (
    style && (
      <>
        <Helmet>
          <title>ArchicoAcademy | Contact</title>
        </Helmet>
        <div>
          <Toaster />
        </div>
        <HeaderDown>
          <h1 style={{ textTransform: "capitalize" }}>{t("əlaqə")}</h1>
          <ul>
            <li>
              <Link to="/">{t("ana səhifə")}</Link>
            </li>
            <li style={{ textTransform: "capitalize" }}>{t("əlaqə")}</li>
          </ul>
        </HeaderDown>
        <Shape bgColor={"#f5f4f9"} />
        <section className={style.contact}>
          <div className={`container ${style.contact__container}`}>
            <div className={style.contact__container__info}>
              <div className={`row ${style.contact__container__info__row}`}>
                <div
                  className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.contact__container__info__row__col}`}
                >
                  <div
                    className={style.contact__container__info__row__col__card}
                  >
                    <div
                      className={
                        style.contact__container__info__row__col__card__icon
                      }
                    >
                      <ImPhone />
                    </div>
                    <div
                      className={
                        style.contact__container__info__row__col__card__text
                      }
                    >
                      <a
                        className={
                          style.contact__container__info__row__col__card__text__a
                        }
                        href={
                          tel &&
                          tel.map((s) => {
                            return "tel:" + s.value;
                          })
                        }
                      >
                        {tel &&
                          tel.map((s) => {
                            return s.value;
                          })}
                      </a>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.contact__container__info__row__col}`}
                >
                  <div
                    className={style.contact__container__info__row__col__card}
                  >
                    <div
                      className={
                        style.contact__container__info__row__col__card__icon
                      }
                    >
                      <MdLocationOn />
                    </div>
                    <div
                      className={
                        style.contact__container__info__row__col__card__text
                      }
                    >
                      <p
                        className={
                          style.contact__container__info__row__col__card__text__a
                        }
                      >
                        {addres &&
                          addres.map((s) => {
                            return s.value;
                          })}
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className={`col-lg-4 col-md-12 col-sm-12 col-12 ${style.contact__container__info__row__col}`}
                >
                  <div
                    className={style.contact__container__info__row__col__card}
                  >
                    <div
                      className={
                        style.contact__container__info__row__col__card__icon
                      }
                    >
                      <HiOutlineMail />
                    </div>
                    <div
                      className={
                        style.contact__container__info__row__col__card__text
                      }
                    >
                      <a
                        href={
                          email &&
                          email.map((s) => {
                            return "mailto:" + s.value;
                          })
                        }
                        className={
                          style.contact__container__info__row__col__card__text__a
                        }
                      >
                        {email &&
                          email
                            .filter((s) => s.key === "email")
                            .map((s) => {
                              return s.value;
                            })}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.contact__container__main}>
              <div className={style.contact__container__main__form}>
                <div className={style.contact__container__main__form__header}>
                  <h5
                    className={style.contact__container__main__form__header__h5}
                  >
                    {t("bizimlə əlaqə")}
                  </h5>
                  <p
                    className={style.contact__container__main__form__header__p}
                  >
                    {t(
                      "Sualınıza cavab almaq üçün aşağıdakı formanı doldurun və mesaj göndərin"
                    )}
                  </p>
                </div>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    messageBody: "",
                  }}
                  onSubmit={(values) => {
                    contactFormsPost(values);
                  }}
                >
                  {({ handleSubmit, handleChange }) => (
                    <form
                      onSubmit={handleSubmit}
                      className={style.contact__container__main__form__fr}
                    >
                      <label
                        className={
                          style.contact__container__main__form__fr__label
                        }
                      >
                        {t("ad")}
                      </label>
                      <input
                        className={
                          style.contact__container__main__form__fr__input
                        }
                        type="text"
                        onChange={handleChange}
                        name="name"
                      />
                      <label
                        className={
                          style.contact__container__main__form__fr__label
                        }
                      >
                        {t("email")}
                      </label>
                      <input
                        className={
                          style.contact__container__main__form__fr__input
                        }
                        type="email"
                        onChange={handleChange}
                        name="email"
                      />
                      <label
                        className={
                          style.contact__container__main__form__fr__label
                        }
                      >
                        {t("mesaj")}
                      </label>
                      <textarea
                        className={
                          style.contact__container__main__form__fr__textarea
                        }
                        name="messageBody"
                        onChange={handleChange}
                        rows="5"
                      ></textarea>
                      <button
                        className={
                          style.contact__container__main__form__fr__button
                        }
                        type="submit"
                      >
                        {t("göndər")}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  );
};

export default Contact;
