import React from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import style from "../assets/css/featuredblogs.module.scss";
import { IMAGE_URL } from "../constants";
import {
  fetchFeaturedBlog,
  getFeaturedBlogs,
  getFeaturedStatus,
} from "../features/blogSlice";
import { useEffect } from "react";
import LoadingBox from "./LoadingBox";
import { useTranslation } from "react-i18next";

const FeaturedBlogs = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const blogs = useSelector(getFeaturedBlogs);
  const status = useSelector(getFeaturedStatus);
  const navigate = useNavigate();
  const searchRef = useRef();
  useEffect(() => {
    dispatch(fetchFeaturedBlog());
  }, [dispatch]);
  if (status === "loading") return <LoadingBox />;
  return (
    style && (
      <div className={`col-lg-3 ${style.col3}`}>
        <div className={style.col3__search}>
          <div>
            <input
              className={style.col3__search__input}
              type="text"
              placeholder={`${t("xəbəri axtar")}...`}
              ref={searchRef}
            />
          </div>
          <div>
            <button
              onClick={() => {
                navigate(`/blogs?search=${searchRef.current.value}`);
              }}
              className={style.col3__search__button}
            >
              {t("axtar")}
            </button>
          </div>
        </div>
        <div className={style.col3__selected}>
          <div className={style.col3__selected__title}>
            <h3 className={style.col3__selected__title__h3}>
              {t("seçilmiş xəbərlər")}
            </h3>
          </div>
          <div className={style.col3__selected__list}>
            {blogs &&
              blogs.map((blog) => (
                <div key={blog.id} className={style.col3__selected__list__card}>
                  <div className={style.col3__selected__list__card__image}>
                    <Link
                      className={style.col3__selected__list__card__body__link}
                      to={`/blogs/${blog.id}`}
                    >
                      <img
                        className={style.col3__selected__list__card__image__img}
                        src={`${IMAGE_URL}${blog.image.filePath}`}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className={style.col3__selected__list__card__body}>
                    <Link
                      className={style.col3__selected__list__card__body__link}
                      to={`/blogs/${blog.id}`}
                    >
                      {blog.title}
                    </Link>
                    <span
                      className={style.col3__selected__list__card__body__span}
                    >
                      {blog.createdAt.slice(0, 10)}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default FeaturedBlogs;
